import React, { memo, createElement } from "react";
import { styled } from "../../stitches.config";
import Link from "../../components/Link";

import { ReactComponent as FacebookIcon } from "../assets/icons/social/facebook-black.svg?react";
import { ReactComponent as TikTokIcon } from "../assets/icons/social/tiktok-black.svg?react";
import { ReactComponent as YouTubeIcon } from "../assets/icons/social/youtube-black.svg?react";
import { ReactComponent as InstagramIcon } from "../assets/icons/social/instagram-black.svg?react";

import { getSocialMediaLinks } from "../../config";

const SocialIcons = {
    facebook: FacebookIcon,
    tiktok: TikTokIcon,
    youtube: YouTubeIcon,
    instagram: InstagramIcon
} as const;

type FooterSocialIconProps = {
    type: keyof typeof SocialIcons;
    brand?: Config.Brand;
    color?: 'auto' | 'white' | 'black';
};

const FooterSocialIcon: React.FC<FooterSocialIconProps> = memo(({ type, brand = window.BRAND, color }) => {

    const IconElement = createElement(SocialIcons[type], {});
    const IconLink = getSocialMediaLinks(brand)[type];

    const ColorProps = color == 'white' ? { white: true } : color == 'black' ? { black: true } : {};

    return (
        <SocialIconWrapper to={IconLink} {...ColorProps}>
            {IconElement}
        </SocialIconWrapper>
    );

});

export default FooterSocialIcon;

const SocialIconWrapper = styled(Link, {
    '& > svg > path': {
        fill: '$grayscale100'
    },
    variants: {
        white: {
            true: {
                '& > svg > path': {
                    fill: '$grayscale0Fixed'
                }
            },
            false: {}
        },
        black: {
            true: {
                '& > svg > path': {
                    fill: '$grayscale100Fixed'
                }
            },
            false: {}
        }
    }
});